<!-- 生成实验模板弹窗 -->
<template>
    <a-modal :width="600" :visible="visible" @cancel="handleCancel" title="实验克隆">
    <a-spin :spinning="loading" tip="正在加载">
        <a-form-model size="small" ref="formRef" :model="formData" :labelCol="{
            xs: { span: 24 },
            sm: { span: 4 },
        }" :wrapper-col="{ span: 19 }" :rules="rules">
            <a-form-model-item label="实验名称" prop="title">
                <a-input v-model="formData.title" placeholder="请输入" autocomplete="off" :maxLength="30"></a-input>
            </a-form-model-item>
            <a-form-model-item label="记录本" has-feedback prop="noteBookId">
                <a-select placeholder="请选择" v-model="formData.noteBookId">
                    <a-select-option v-for="(item, index) in experimentInitData.noteBookList" :key="item.value">
                        {{ item.text }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <div>
                选择复制模块
            </div>
            <a-form-model-item :wrapper-col="{ span: 24 }">
                <div class="clone-table">
                    <div class="clone-table-header">
                        <div class="clone-table-head">
                            模块名称
                        </div>
                        <div class="clone-table-head">
                            <a-checkbox :indeterminate="moduleIndeterminate" :checked="moduleAllChecked"
                                @change="(e) => toggleModuleAllChecked(e)">
                                模块
                            </a-checkbox>
                        </div>
                        <div class="clone-table-head">
                            <a-checkbox :indeterminate="moduleDataIndeterminate" :checked="moduleDataAllChecked"
                                @change="toggleModuleDataCheck">
                                数据
                            </a-checkbox>
                        </div>
                    </div>
                    <div v-for="(item, index) in formData.checkList" class="clone-table-row">
                        <div class="clone-table-col">
                            {{ item.label }}
                        </div>
                        <div class="clone-table-col">
                            <a-checkbox class="checkbox" :checked="getModuleCheckedStatu(item.key)"
                                @change="(e) => moduleCheckChange(e, item.key)">

                            </a-checkbox>
                        </div>
                        <div class="clone-table-col">
                            <a-checkbox class="checkbox" :disabled="!getModuleCheckedStatu(item.key) || item.isGrid || !copyExperimentDataAuthorization"
                                :checked="getModuleDataChcekedStatu(item.key) || (getModuleCheckedStatu(item.key) && item.isGrid)"
                                @change="(e) => moduleDataCheckedChange(e, item.key)">
                            </a-checkbox>
                        </div>
                    </div>
                </div>
            </a-form-model-item>
        </a-form-model>
    </a-spin>
        <template slot="footer">
            <div class="btns-ctn">
                <a-button class="concel-btn" @click="handleCancel">
                    取消
                </a-button>
                <a-button type="primary" :disabled="selectedModuleList.length === 0" class="confirm-btn"
                    @click="handleConfirm">
                    确定
                </a-button>
            </div>
        </template>
    </a-modal>
</template>

<style lang="less" scoped>
.clone-table {
    border: 1px solid #EBEBEB;
    margin-top: 16px;
    font-size: 14px;

    .clone-table-header {
        display: flex;
        align-items: center;
        background: #EEEEEE;

        .clone-table-head {
            flex-grow: 1;
            width: 0;
            text-align: center;
            border-right: 1px solid #EBEBEB;
            //&:nth-child(2)
        }
    }

    .clone-table-row {
        display: flex;

        .clone-table-col {
            flex: 1;
            width: center;
            //padding: ;
            text-align: center;
            word-break: break-all;
            border-right: 1px solid #EBEBEB;
            border-bottom: 1px solid #EBEBEB;

            .checkbox {
                margin-right: 45px;
            }
        }
    }
}

.btns-ctn {
    text-align: center;
    width: 100%;

    .concel-btn {
        background: #eeeeee;
    }
}
</style>

<script>
import { computed, ref, watch } from '@vue/composition-api';
import { message } from 'ant-design-vue';
import { copyExperiment } from '../../../api/experiment';
import { getAddExperimentInitData } from '../../../api/experiment';

const tables = [
    `equipment-table`, `experiment-material-table`, `sample-info-table`, `experiment-document-table`, `reference-table`, `related-experiment-table`
]
export default {
    /* props: ['visible', 'eData', 'id'], */
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        eData: {
            type: Object || null,
            required: true
        },
        id: {
            type: Number | null | String,
            required: true
        },
        copyExperimentDataAuthorization: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        const handleCancel = () => {
            context.emit('update:visible', false);
        }
        const formRef = ref(null);
        const formData = ref({
            title: '',
            noteBookId: undefined,
            checkList: []
        });

        // 校验规则
        const rules = ref({
            title: [{
                required: true,
                message: '请输入',
                whitespace: true
            }],
            noteBookId: [{
                required: true,
                message: '请选择'
            }]
        })

        /**********************               选择复制模块 start                 *********************/
        const selectedModuleList = ref([]); // 已勾选的模块
        const selectedModuleDataList = ref([]); // 已勾选的数据模块
        const loading = ref(false); // 加载中
        const experimentInitData = ref({
            noteBookList: []
        })
        watch(() => props.visible, async (newVal) => {
            if (newVal) {
                loading.value = true;
                formData.value = {
                    title: '',
                    noteBookId: undefined,
                    checkList: []
                }
                selectedModuleList.value = [];
                selectedModuleDataList.value = [];
                const experRes = await getAddExperimentInitData();
                if (experRes.code === 200) {
                    experimentInitData.value = {
                        noteBookList: experRes.data,
                        experimentTemplateList: []
                    };
                }
                formRef.value && formRef.value.resetFields();
                selectedModuleList.value = [];
                selectedModuleDataList.value = [];
                formData.value.checkList = props.eData.list.map((element) => {
                    return {
                        key: element.key,
                        label: element.label,
                        isModule: false,
                        hasData: false,
                        isGrid: element.type === 'grid'
                    }
                })
                loading.value = false;
            }
        })
        // 获取模块是否被选择
        const getModuleCheckedStatu = (key) => {
            return selectedModuleList.value.indexOf(key) !== -1;
        }
        // 模块选择改变事件
        const moduleCheckChange = (e, key) => {
            const index = selectedModuleList.value.indexOf(key);
            if (index === -1) {
                selectedModuleList.value.push(key);
            }
            else {
                selectedModuleList.value.splice(index, 1);
                const dataIndex = selectedModuleDataList.value.indexOf(key);
                if (dataIndex !== -1) {
                    selectedModuleDataList.value.splice(dataIndex, 1);
                }
            }
            return
        }
        // 模块是否部分选择
        const moduleIndeterminate = computed(() => {
            return selectedModuleList.value.length > 0 && selectedModuleList.value.length < formData.value.checkList.length;
        })
        // 模块是否全选
        const moduleAllChecked = computed(() => {
            return selectedModuleList.value.length !== 0 && selectedModuleList.value.length === formData.value.checkList.length;
        })
        const toggleModuleAllChecked = (e) => {
            // 目前是全选
            if (selectedModuleList.value.length !== 0 && selectedModuleList.value.length === formData.value.checkList.length) {
                selectedModuleList.value.splice(0, selectedModuleList.value.length);
            }
            else {
                selectedModuleList.value = formData.value.checkList.map((element) => {
                    return element.key;
                })
            }
        }

        // 获取数据是否被选择
        const getModuleDataChcekedStatu = (key) => {
            return selectedModuleDataList.value.indexOf(key) !== -1;
        }
        // 数据选择改变事件
        const moduleDataCheckedChange = (e, key) => {
            const index = selectedModuleDataList.value.indexOf(key);
            if (index === -1) {
                selectedModuleDataList.value.push(key);
            }
            else {
                selectedModuleDataList.value.splice(index, 1);
            }
            return
        }
        // 获取数据是否被部分选择
        const moduleDataIndeterminate = computed(() => {
            return selectedModuleDataList.value.length > 0 && selectedModuleDataList.value.length < formData.value.checkList.length;
        })
        // 获取数据是否全选
        const moduleDataAllChecked = computed(() => {
            return selectedModuleDataList.value.length !== 0 && selectedModuleDataList.value.length === formData.value.checkList.length;
        })
        // 数据checkbox toggle
        const toggleModuleDataCheck = () => {
            if (props.copyExperimentDataAuthorization && selectedModuleDataList.value.length < selectedModuleList.value.length) {
                selectedModuleDataList.value = JSON.parse(JSON.stringify(selectedModuleList.value));
            }
            else {
                selectedModuleDataList.value = [];
            }
        }
        // 确认
        const handleConfirm = () => {
            formRef.value && formRef.value.validate().then(async (valid) => {
                if(!valid) return ;
                let newList = props.eData.list.filter((element) => {
                    return selectedModuleList.value.indexOf(element.key) !== -1;
                });
                newList = JSON.parse(JSON.stringify(newList));
                newList.forEach(element => {
                    // 去除数据
                    if (selectedModuleDataList.value.indexOf(element.key) === -1) {
                        if (tables.indexOf(element.type) !== -1) {
                            element.options.customData = [];
                        }
                        else if (element.type !== 'grid') {
                            element.options.customData = undefined;
                        }
                    }
                });
                const newContent = JSON.parse(JSON.stringify(props.eData));
                newContent.list = newList;
                const res = await copyExperiment({
                    experimentId: props.id,
                    title: formData.value.title,
                    noteBookId: formData.value.noteBookId,
                    content: newContent
                });
                if(res.code === 200 || res.code === 204) {
                    message.success(res.message);
                    context.emit('update:visible', false);
                }
            })
        }
        /**********************               选择复制模块 end                   ********************/
        return {
            handleCancel,
            formRef,
            formData,
            selectedModuleList,
            selectedModuleDataList,
            getModuleCheckedStatu,
            moduleCheckChange,
            getModuleDataChcekedStatu,
            moduleDataCheckedChange,
            moduleIndeterminate,
            moduleAllChecked,
            toggleModuleAllChecked,
            toggleModuleDataCheck,
            moduleDataIndeterminate,
            moduleDataAllChecked,
            handleConfirm,
            experimentInitData,
            rules,
            loading
        }
    }
}
</script>