<template>
    <a-modal :width="600" :visible="visible" title="撤销归档申请" @cancel="handleCancel">
        <a-spin :spinning="loading" tip="正在提交中">
            <a-form-model ref="formRef" :model="formData" size="small" style="width: 80%;margin: 0 auto;" :colon="true" :labelCol="{
                xs: { span: 24 },
                sm: { span: 5 },
            }" :wrapper-col="{ span: 19 }" :rules="rules">
                <a-form-model-item label="登录密码" prop="PassWord">
                    <a-input-password v-model="formData.PassWord" placeholder="请输入密码" type="password" autocomplete="off">
                    </a-input-password>
                </a-form-model-item>
            </a-form-model>
        </a-spin>
        <template slot="footer">
            <div class="btns-ctn">
                <a-button :loading="loading" class="concel-btn" @click="handleCancel">
                    取消
                </a-button>
                <a-button :loading="loading" type="primary" class="confirm-btn" @click="handleConfirm">
                    确定
                </a-button>
            </div>
        </template>
    </a-modal>
</template>

<style lang="less" scoped>
.btns-ctn {
    text-align: center;

    .concel-btn {
        background: #eeeeee;
    }
}
</style>

<script>
import { ref, watch } from '@vue/composition-api';
import { message } from 'ant-design-vue';
import { retractarchivingExperiment } from '../../../api/experiment';
import { getPublicRSAKey } from '../../../api/others';
import { setEncrypt } from '../../../tool/encryption';
export default {
    props: ['visible','ExperimentId'],
    setup(props, context) {
        const formData = ref({
            PassWord: null
        });
        // 校验规则
        const rules = ref({
            PassWord: [{
                required: true,
                message: `请输入`
            }]
        })
        const handleCancel = () => {
            context.emit('update:visible', false);
        }
        const formRef = ref(null);
        watch(() => props.visible, (newVal) => {
            if (newVal) {
                formData.value = {
                    PassWord: null,
                }
                formRef.value && formRef.value.resetFields();
            }
        })
        // 确认
        const handleConfirm = () => {
            formRef.value && formRef.value.validate().then( async (valid) => {
                if(valid) {
                    loading.value = true;
                    const resk = await getPublicRSAKey();
                    if(resk.code !== 200) {
                        return ;
                    }

                    const res = await retractarchivingExperiment({
                        ExperimentId: props.ExperimentId,
                        PassWord: setEncrypt(resk.data, formData.value.PassWord) 
                    });
                    if(res.code === 200 || res.code === 204) {
                        message.success(res.message);
                        context.emit('update:visible',false);
                        context.emit('onRetrarchiving')
                    }
                    loading.value = false;
                }
            })
        }

        const loading = ref(false);
        return {
            formData,
            formRef,
            handleCancel,
            handleConfirm,
            rules,
            loading
        }
    }
}
</script>