<template>
    <a-modal :width="600" :visible="visible" title="保存版本" @cancel="handleCancel">
        <a-spin :spinning="loading" tip="正在提交中...">
            <a-form-model ref="formRef" :model="formData" size="small" style="width: 80%;margin: 0 auto;" :colon="true"
                :labelCol="{
                    xs: { span: 24 },
                    sm: { span: 5 },
                }" :wrapper-col="{ span: 19 }" :rules="rules">
                <a-form-model-item label="版本备注" prop="Remark">
                    <a-textarea v-model="formData.Remark" placeholder="请输入版本备注" :auto-size="{ minRows: 3, maxRows: 5 }" />
                </a-form-model-item>
                <a-form-model-item label=" " :colon="false">
                <!--  <p style="color: #FF4D4F; font-size: 14px;"> 实验中不能包含任何保密/涉密信息，请仔细检查！ </p> -->
                    <a-alert message="请做好保密工作，严禁录入涉密信息！" banner />
                </a-form-model-item>
            </a-form-model>
        </a-spin>
        <template slot="footer">
            <div class="btns-ctn">
                <a-button class="concel-btn" :loading="loading" @click="handleCancel">
                    取消
                </a-button>
                <a-button type="primary" :loading="loading" class="confirm-btn" @click="handleConfirm">
                    确定
                </a-button>
            </div>
        </template>
    </a-modal>
</template>

<style lang="less" scoped>
.btns-ctn {
    text-align: center;

    .concel-btn {
        background: #eeeeee;
    }
}
</style>

<script>
import { ref, watch } from '@vue/composition-api';

export default {
    props: ['visible', 'ExperimentId'],
    setup(props, context) {
        // 数据
        const formData = ref({
            Remark: null
        });
        // 规则
        const rules = ref({
            Remark: [{
                required: true,
                whitespace: true,
                message: `请输入`
            }, {
                required: true,
                max: 500,
                message: '备注不能超过500字'
            }]
        })
        const handleCancel = () => {
            context.emit('update:visible', false);
        }
        const formRef = ref(null);
        // 密码项
        const passwordRef = ref(null);
        watch(() => props.visible, (newVal) => {
            if (newVal) {
                formData.value = {
                    Remark: null
                }
                loading.value = false;
                formRef.value && formRef.value.resetFields();
            }
        })
        // 确认归档
        const handleConfirm = () => {
            // 验证表格
            formRef.value && formRef.value.validate().then(async (valid) => {
                if (valid) {
                    loading.value = true;
                    context.emit('onSaveVersion', { remark: formData.value.Remark });
                }
            })
        }
        const loading = ref(false);
        return {
            formData,
            formRef,
            handleCancel,
            handleConfirm,
            rules,
            passwordRef,
            loading
        }
    }
}
</script>