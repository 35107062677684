<template>
    <a-modal :width="600" :visible="visible" title="审核" @cancel="handleCancel">
        <a-spin :spinning="loading" tip="正在加载...">
            <a-form-model ref="formRef" :model="formData" size="small" style="width: 80%;margin: 0 auto;" :colon="true"
                :labelCol="{
                    xs: { span: 24 },
                    sm: { span: 5 },
                }" :wrapper-col="{ span: 19 }" :rules="rules">
                <a-form-model-item label="申请人">
                    {{ applyUser }}
                </a-form-model-item>
                <a-form-model-item label="最后修改人">
                    {{ lastUpdateUser }}
                </a-form-model-item>
                <a-form-model-item label="申请备注">
                    {{ applyRemark }}
                </a-form-model-item> 
                <a-form-model-item label="登录密码" prop="PassWord">
                    <a-input-password v-model="formData.PassWord" placeholder="请输入密码" type="password"
                        autocomplete="off">
                    </a-input-password>
                </a-form-model-item>
                <a-form-model-item label="批阅信息" prop="Remark">
                    <a-textarea v-model="formData.Remark" placeholder="请输入批阅信息"
                        :auto-size="{ minRows: 3, maxRows: 5 }" />
                </a-form-model-item>
                <a-form-model-item label="诚信扣分" prop="LstGoodFaithClauseId">
                    <a-select mode="multiple" v-model="formData.LstGoodFaithClauseId" placeholder="请选择" allowClear>
                        <a-select-option v-for="(item, index) in LstGoodFaithClauseList" :key="item.id" :value="item.id">
                            {{ item.title }}（{{ item.grade }}分）
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-form-model>
        </a-spin>
        <template slot="footer">
            <div class="btns-ctn">
                <a-button class="concel-btn" :disabled="isSubmitting" :loading="isSubmitting" @click="handleCancel">
                    取消
                </a-button>
                <a-button type="primary" :disabled="isSubmitting" :loading="isSubmitting" class="confirm-btn" @click="handleConfirm">
                    确定
                </a-button>
            </div>
        </template>
    </a-modal>
</template>

<style lang="less" scoped>
.btns-ctn {
    text-align: center;

    .concel-btn {
        background: #eeeeee;
    }
}
</style>

<script>
import { ref, watch } from '@vue/composition-api';
import { message } from 'ant-design-vue';
import { auditExperiment, getGoodfaithclauseList, getApplyRemark } from '../../../api/experiment';
import { getPublicRSAKey } from '../../../api/others';
import { setEncrypt } from '../../../tool/encryption';
export default {
    props: ['visible', 'ExperimentId', 'applyUser', 'lastUpdateUser'],
    setup(props, context) {
        const formData = ref({
            PassWord: null,
            Remark: null,
            LstGoodFaithClauseId: undefined
        });
        // 校验规则
        const rules = ref({
            PassWord: [{
                required: true,
                message: `请输入`
            }],
            Remark: [{
                required: true,
                message: `请输入`,
                whitespace: true
            },{
                max: 1000,
                message: '不能超过1000个字符'
            }]
        })
        const handleCancel = () => {
            context.emit('update:visible', false);
        }
        const formRef = ref(null);
        // 是否正在加载数据
        const loading = ref(false);
        // 诚信条款列表
        const LstGoodFaithClauseList = ref([]);
        // 申请备注
        const applyRemark = ref();
        watch(() => props.visible, async (newVal) => {
            if (newVal) {
                loading.value = true;
                formData.value = {
                    PassWord: null,
                    Remark: null,
                    LstGoodFaithClauseId: []
                }
                formRef.value && formRef.value.resetFields();
                const res = await getGoodfaithclauseList();
                if (res.code === 200) {
                    LstGoodFaithClauseList.value = res.data;
                }
                const remarkRes = await getApplyRemark( {
                    ExperimentId: props.ExperimentId
                })
                if(remarkRes.code === 200) {
                    applyRemark.value = remarkRes.data;
                }
                loading.value = false;
            }
        })

        const isSubmitting = ref(false);

        // 确认
        const handleConfirm = () => {
            isSubmitting.value = true;
            formRef.value && formRef.value.validate().then(async (valid) => {
                if (valid) {
                    const encodeRes = await getPublicRSAKey();
                    if (encodeRes.code === 200) {
                        const rsaKey = encodeRes.data;
                        const res = await auditExperiment({
                            ExperimentId: props.ExperimentId,
                            PassWord: setEncrypt(rsaKey, formData.value.PassWord),
                            Remark: formData.value.Remark,
                            LstGoodFaithClauseId: formData.value.LstGoodFaithClauseId.join(',')
                        });
                        if (res.code === 200 || res.code === 204) {
                            message.success(res.message);
                            context.emit('update:visible', false);
                            context.emit('onAudit')
                        }
                    }
                    else {
                        message.error(res.message);
                        return;
                    }
                }
                isSubmitting.value = false;
            })
            !formRef.value && (isSubmitting.value = false);
        }
        return {
            formData,
            formRef,
            handleCancel,
            handleConfirm,
            rules,
            loading,
            LstGoodFaithClauseList,
            applyRemark,
            isSubmitting

        }
    }
}
</script>