<template>
    <a-modal :width="600" :visible="visible" title="存为模板" @cancel="handleCancel">
        <a-spin :spinning="loading">
            <a-form-model ref="formRef" :model="formData" size="small" style="width: 80%;margin: 0 auto;" :colon="true"
                :labelCol="{
                    xs: { span: 24 },
                    sm: { span: 5 },
                }" :wrapper-col="{ span: 19 }">
                <a-form-model-item label="名称" prop="name" :rules="[{ required: true, message: '请输入', whitespace: true }, { max: 50, message: '50个字符以内' }]">
                    <a-input v-model="formData.name" placeholder="请输入"></a-input>
                </a-form-model-item>
                <a-form-model-item label="描述" prop="description" :rules="[{ max: 200, message: '200个字符以内' }]">
                    <a-textarea v-model="formData.description" placeholder="请输入"
                        :auto-size="{ minRows: 3, maxRows:8 }" />
                </a-form-model-item>
                <a-form-model-item required label="行业领域" prop="industrySector"
                    :rules="[{ required: true, message: '请选择' }]">
                    <a-cascader v-model="formData.industrySector"
                        :field-names="{ label: 'name', value: 'id', children: 'children' }"
                        :options="initData.industrySectorList" placeholder="请选择" />
                </a-form-model-item>
                <a-form-model-item required label="实验类型" prop="experimentType"
                    :rules="[{ required: true, message: '请选择' }]">
                    <a-select v-model="formData.experimentType" placeholder="请选择"
                    show-search
                            :filterOption="experimentTypeFilter">
                        <a-select-option v-for="(item, index) in initData.dataList" :key="item.value"
                            :value="item.value" 
                            >
                            {{ item.text }}
                        </a-select-option>
                    </a-select>
                   <!--  <div  style="margin-top: 6px;">
                        <span style="font-size: 14px; color: rgba(0, 0, 0, 0.65);"> 找不到实验类型？</span>
                        <a-button type="link" size="small" @click="onAddExperimentTypeClick"> 点击添加</a-button>
                    </div> -->
                </a-form-model-item>
            </a-form-model>
        </a-spin>
        <add-new-experiment-type :visible.sync="addExperimentTypeModalVisible" @onSuccess="handleAddExperimentTypeSuccess">
        </add-new-experiment-type>
        <template slot="footer">
            <div class="btns-ctn">
                <a-button class="concel-btn" @click="handleCancel">
                    取消
                </a-button>
                <a-button type="primary" class="confirm-btn" :disabled="loading" @click="handleConfirm">
                    确定
                </a-button>
            </div>
        </template>
    </a-modal>
</template>

<style lang="less" scoped>
.btns-ctn {
    text-align: center;

    .concel-btn {
        background: #eeeeee;
    }
}
</style>

<script>
import { ref, watch } from '@vue/composition-api';
import { message } from 'ant-design-vue';
import { getExperimentTemplateInitData, addExperimentTemplate } from '../../../api/experimentTemplate';
//import addExperimentTypeModal
import addNewExperimentType from '../../experimentTemplate/components/addNewExperimentType.vue';
export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        content: {
            type: Object,
            required: true
        }
    },
    components: {
        addNewExperimentType
    },
    setup(props, context) {
        // 表格数据
        const formData = ref({
            name: '',   // 模板名
            description: '',    // 描述
            content: null,  // 模板
            experimentType: undefined, // 实验类型
            industrySector: undefined,  // 行业类型
        });
        // 取消
        const handleCancel = () => {
            context.emit('update:visible', false);
        }
        // 表格实例
        const formRef = ref(null);
        // 加载中
        const loading = ref(false);
        // 初始化数据
        const initData = ref({
            industrySectorList: [],
            dataList: []
        })
        watch(() => props.visible, async (newVal) => {
            if (newVal) {
                formData.value = {
                    name: '',   // 模板名
                    description: '',    // 描述
                    content: null,  // 模板
                    experimentType: undefined, // 实验类型
                    industrySector: undefined  // 行业类型
                }
                formRef.value && formRef.value.resetFields();
                loading.value = true;
                const res = await getExperimentTemplateInitData();
                if (res.code === 200) {
                    initData.value = res.data;
                }
                loading.value = false;
            }
        })
        // 确认
        const handleConfirm = () => {
            formRef.value && formRef.value.validate().then(async (valid) => {
                if (valid) {
                    const data = JSON.parse(JSON.stringify(formData.value));
                    // 行业类型
                    data.industrySector = data.industrySector.join(',');
                    // 电子记录表数据
                    data.content = clearAllFileData(JSON.parse(JSON.stringify(props.content)));
                    data.projectId = context.root.$route.query.projectId;
                    const res = await addExperimentTemplate(data);
                    if (res.code === 200 || res.code === 204) {
                        message.success(res.message);
                        context.emit('update:visible', false);
                    }
                }
            }).catch(e => e);
        }
        // 清除所有的模板数据
        const clearAllFileData = (eData) => {
            eData.list.forEach(Element => {
                if (Element.type === 'grid') {
                    goDeepIfColumns(Element)
                }
                else if (Element.type === 'experiment-document-table' || Element.type === 'experiment-material-table') {
                    Element.options.customData = [];
                }
            })
            return eData;
        }
        // 如果是布局控件嵌套的深入排查
        const goDeepIfColumns = (gird) => {
            gird.columns.forEach(Element => {
                Element && Element.list && Element.list.length && Element.list.forEach(element => {
                    if (element.type === 'grid') {
                        goDeepIfColumns(element);
                    }
                    else if (element.type === 'experiment-document-table' || element.type === 'experiment-material-table') {
                        element.options.customData = [];
                    }
                })
            })
        }
        // 实验类型搜索过滤
        const experimentTypeFilter = (input, option) => {
            return option.componentOptions.children[0].text.indexOf(input.toString()) !== -1;
        }

        /**********************           添加实验类型                     *********************/
        // 点击添加实验类型回调
        const onAddExperimentTypeClick = () => {
            addExperimentTypeModalVisible.value = true;
        }
        // 添加实验类型对话框可见变量
        const addExperimentTypeModalVisible = ref(false);
        // 添加成功后回调
        const handleAddExperimentTypeSuccess = async (data) => {
            loading.value = true;
            const initRes = await getExperimentTemplateInitData();
            if (initRes.code === 200) {
                initData.value = initRes.data;
                formData.value.experimentType = data;
                formRef.value.validate('experimentType')
            }
            loading.value = false;
        }
        /**************************************************************************************/
        return {
            formData,
            formRef,
            handleCancel,
            loading,
            initData,
            handleConfirm,
            experimentTypeFilter,
            handleAddExperimentTypeSuccess,
            addExperimentTypeModalVisible,
            onAddExperimentTypeClick,
        }
    }
}
</script>