<template>
    <a-modal :width="600" title="批阅信息" :visible.sync="visible" @cancel="handleCancel" :footer="null">
        <a-spin :spinning="loading" tip="正在加载中...">
            <a-form-model style="width: 85%; margin: 0 auto;" :labelCol="{
                xs: { span: 24 },
                sm: { span: 4 },
            }" :wrapper-col="{ span: 19 }">
                <a-form-model-item label="审批人">
                    {{ formData.aduitName }}
                </a-form-model-item>
                <a-form-model-item label="审批时间">
                    {{ formData.aduitDateTime }}
                </a-form-model-item>
                <a-form-model-item label="批阅信息">
                    {{ formData.remark }}
                </a-form-model-item>
                <a-form-model-item label="扣分情况">
                    <div v-if="formData.goodFaithBehaviorOutputs && formData.goodFaithBehaviorOutputs.length > 0">
                        <a-table :pagination="false" size="small" :columns="goodFaithColumns" :data-source="formData.goodFaithBehaviorOutputs">
                        </a-table>
                    </div>
                    <div v-if="!formData.goodFaithBehaviorOutputs || formData.goodFaithBehaviorOutputs.length === 0"> ——
                    </div>
                </a-form-model-item>
                <a-form-model-item  v-if="formData.goodFaithBehaviorOutputs && formData.goodFaithBehaviorOutputs.length > 0" label=" " :colon="false">
                    <a :href="checkDetailHref" target="_blank"> 查看扣分详情 </a>
                </a-form-model-item>
            </a-form-model>

        </a-spin>
    </a-modal>
</template>

<script>
import { watch, ref, computed } from 'vue-demi';
import { getReviewInfo } from '../../../api/experiment';
import { message } from 'ant-design-vue';
import router from '../../../router';
const goodFaithColumns = [
    {
        title: '扣分条款',
        dataIndex: 'goodFaithClauseTitle',
        scopedSlots: { customRender: "goodFaithClauseTitle" }
    },
    {
        title: '诚信分',
        dataIndex: 'grade'
    }
]
export default {
    props: {
        visible: {
            type: Boolean,
            required: true
        },
        id: {
            type: String | Number | undefined | null,
            required: true
        },
        ExperimentNo: {
            type: String,
            required: true
        }
    },
    setup(props, context) {
        const handleCancel = () => {
            context.emit("update:visible", false);
        };
        const formData = ref({});
        const loading = ref(false);
        watch(() => props.visible, async (value) => {
            if (value) {
                loading.value = true;
                const res = await getReviewInfo({
                    experimentId: props.id
                })
                if (res.code === 200) {
                    formData.value = res.data;
                }
                loading.value = false;
            }

        })

        const checkDetailHref = computed(() => {
            const href = router.resolve({
                name: 'myGoodFaithBahavior',
                query: {
                    ExperimentNo: props.ExperimentNo,
                }
            });
            return href.href
        })

        return {
            handleCancel,
            loading,
            goodFaithColumns,
            formData,
            checkDetailHref
        };
    }
}
</script>

<style lang="less" scoped>
.btns-ctn {
    text-align: center;

    .concel-btn {
        background: #eeeeee;
    }
}

/deep/ .ant-table-thead {
				background: #F0F5FF;
			}

			/deep/ .ant-table-small>.ant-table-content>.ant-table-body {
				margin: 0px;
			}

			/deep/ .ant-table-row {
				td {
					padding: 7px;
				}
			}
</style>